<script>
  export let t0 = 0,
    t1 = 0,
    t2 = 0,
    t3 = 0;
</script>

<h2>--- Performance ---</h2>
<p>
  Setup
  <em>{t1 - t0}</em>
  milisseconds<br />
  First puzzle
  <em>{t2 - t1}</em>
  milisseconds<br />
  Second puzzle
  <em>{t3 - t2}</em>
  milisseconds<br />
  Total run
  <em>{t3 - t0}</em>
  milisseconds
</p>
